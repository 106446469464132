<template>
  <main>
    <h2 class="header">Bienvenido, {{ user.email }}</h2>
    <ul class="row no-sides">
      <li v-for="(investment, index) in investor.investments" :key="index">
        <Investment
          :investment="investment"
          class="body" />
      </li>
      <li>
        <Report />
      </li>
    </ul>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import Investment from '@/components/Investment.vue';
import Report from '@/components/Report.vue';

export default {
  name: 'Dashboard',
  components: {
    Investment,
    Report,
  },
  computed: {
    ...mapState(['investor', 'user']),
  },
};
</script>

<style scoped>
  .header{
    color: var(--secondary);
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-content: space-around;
  }

  .no-sides {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
</style>
