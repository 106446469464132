<template>
  <article class="box box-limit text-center">
    <h2 class="capitalize">{{ portfolioName }}</h2>
    <p class="text-large margin">${{ valueString }} USD</p>
    <p class="accent text-small">{{ growthString }}%</p>
  </article>
</template>

<script>
import { mapState } from 'vuex';
import investing from '../investing';

const {
  calculateInvestmentGrowth,
  calculateInvestmentValue,
  cleanPortfolioName,
  twoFractionDigits,
} = investing;

export default {
  computed: {
    ...mapState(['portfolios']),
    value() {
      return calculateInvestmentValue(this.investment, this.portfolio);
    },
    valueString() {
      return twoFractionDigits(this.value);
    },
    portfolio() {
      return this.portfolios[this.investment.portfolio];
    },
    growth() {
      return calculateInvestmentGrowth(this.investment, this.value);
    },
    growthString() {
      return twoFractionDigits(this.growth);
    },
    portfolioName() {
      return cleanPortfolioName(this.investment.portfolio);
    },
  },
  props: [
    'investment',
  ],
  setup() {
  },
};
</script>

<style scoped>
  .text-center {
    text-align: center;
  }

  .text-large {
    font-size: 120%;
  }

  .text-small {
    font-size: 80%;
  }

  .box {
    background-color: var(--primary);
    border-radius: 15px;
    padding: 15px;
  }

  .margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .accent {
    background-color: var(--accent);
    border-radius: 15px;
    display: inline-block;
    margin: 0px;
    padding: 5px;
  }

  .box-limit {
    min-width: 300px;
    max-width: 600px;
  }

  .capitalize {
    text-transform: capitalize;
  }
</style>
