<template>
  <div class="box box-limit text-center">
    <h2>Reporte</h2>
    <p class="margin">Muestra el valor de tu inversion y del portafolio</p>
    <button @click="onClick" class="button">Abrir reporte</button>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$router.push('/report');
    },
  },
};
</script>

<style scoped>
  .text-center {
    text-align: center;
  }

  .box {
    background-color: var(--primary);
    border-radius: 15px;
    padding: 15px;
  }

  .box-limit {
    min-width: 300px;
    max-width: 600px;
  }

  .button {
    background-color: var(--accent);
    border-radius: 15px;
    border: 0.1rem solid;
    border-color: var(--background);
    padding: 5px;
  }

  .margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .text-large {
    font-size: 120%;
  }
</style>
